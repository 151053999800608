// src/pages/_app.page.tsx
import '@stripe/stripe-js'
import { withTRPC } from '@trpc/next'
import App, { AppContext, AppProps } from 'next/app'
import { ErrorBoundary } from 'react-error-boundary'
import superjson from 'superjson'

import { AuthProvider } from 'src/providers/AuthProvider'
import { AuthorizedApolloProvider } from 'src/providers/AuthorizedApolloProvider'
import { ConfirmationProvider } from 'src/providers/ConfirmationProvider/ConfirmationProvider'
import { ConsentProvider } from 'src/providers/ConsentProvider'
import { ImagePreviewProvider } from 'src/providers/ImagePreviewProvider'
// import { ForceAccountSetupProvider } from 'src/providers/ForceAccountSetupProvider'
import { IntercomProvider } from 'src/providers/IntercomProvider'
import { MenuStateProvider } from 'src/providers/MenuStateProvider'
import { ModalProvider } from 'src/providers/ModalProvider'
import { NotificationProvider } from 'src/providers/NotificationProvider'
import { RecaptchaProvider } from 'src/providers/RecaptchaProvider'
import { SortProvider } from 'src/providers/SortProvider'
import { UserProvider } from 'src/providers/UserProvider'

import { Fallback } from 'src/components/layouts/Fallback'

import { env } from 'src/env/client.mjs'
import { AppRouter } from 'src/server/router'
import 'src/styles/globals.css'
import { isAllowedIp } from 'src/utils/allowList'

// eslint-disable-next-line import/no-named-as-default
import ServiceUnavailablePage from './503/index.page'

export { reportWebVitals } from 'next-axiom'

interface MyAppProps extends AppProps {
  pageProps: AppProps['pageProps'] & { ip?: string }
}

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  // If the app is in maintenance mode and the request isn't from an allowed IP
  if (env.NEXT_PUBLIC_MAINTENANCE_MODE === '1' && !isAllowedIp(pageProps?.ip)) {
    return <ServiceUnavailablePage />
  }

  return (
    // error handler
    <ErrorBoundary FallbackComponent={Fallback}>
      {/* Providers */}
      <MenuStateProvider>
        <ImagePreviewProvider>
          <IntercomProvider>
            <AuthProvider>
              <AuthorizedApolloProvider>
                <ConsentProvider>
                  <UserProvider>
                    <ModalProvider>
                      <SortProvider>
                        <ConfirmationProvider>
                          <NotificationProvider>
                            {/* <ForceAccountSetupProvider> */}
                            <RecaptchaProvider>
                              {/* Page */}
                              <Component {...pageProps} />
                            </RecaptchaProvider>
                            {/* </ForceAccountSetupProvider> */}
                          </NotificationProvider>
                        </ConfirmationProvider>
                      </SortProvider>
                    </ModalProvider>
                  </UserProvider>
                </ConsentProvider>
              </AuthorizedApolloProvider>
            </AuthProvider>
          </IntercomProvider>
        </ImagePreviewProvider>
      </MenuStateProvider>
    </ErrorBoundary>
  )
}

// Only define if the app is in maintenance mode
if (env.NEXT_PUBLIC_MAINTENANCE_MODE === '1') {
  MyApp.getInitialProps = async (appContext: AppContext) => {
    const pageProps = await App.getInitialProps(appContext)

    // Pull out the IP (x-forwarded-for header) from the request headers
    // Or use the remoteAddress value
    const { ctx } = appContext
    const forwarded = ctx.req?.headers['x-forwarded-for']
    const ip =
      forwarded && !Array.isArray(forwarded)
        ? forwarded.split(/, /)[0]
        : ctx.req?.connection.remoteAddress

    return { pageProps: { ...pageProps, ip } }
  }
}

const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return ''
  }
  if (process.browser) return '' // Browser should use current path
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}` // SSR should use vercel url

  return `http://localhost:${process.env.PORT ?? 3000}` // dev SSR should use localhost
}

export default withTRPC<AppRouter>({
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    const url = `${getBaseUrl()}/api/trpc`

    return {
      url,
      transformer: superjson
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true
})(MyApp)
