import { useRouter } from 'next/router'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

type MenuState = {
  isOpen: boolean
  isAccountsVisible: boolean
  setIsOpen: (v: boolean, accountsVisible?: boolean) => void
}

export const MenuStateContext = createContext<MenuState>({
  isOpen: false,
  isAccountsVisible: false,
  setIsOpen: () => {}
})

export const MenuStateProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  const [isAccountsVisible, setIsAccountsVisible] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [router.pathname])

  const setIsOpenFn = useCallback(
    (v: boolean, accountsVisible: boolean = false) => {
      setIsOpen(v)
      setIsAccountsVisible(accountsVisible)
    },
    []
  )

  const memoisedValue = useMemo<MenuState>(
    () => ({
      isOpen,
      isAccountsVisible,
      setIsOpen: setIsOpenFn
    }),
    [isOpen, setIsOpenFn, isAccountsVisible]
  )

  return (
    <MenuStateContext.Provider value={memoisedValue}>
      {children}
    </MenuStateContext.Provider>
  )
}

export const useMenuState = () => useContext(MenuStateContext)
